<template>
  <section id="partnership-detail">
    <bg-grid>
      <bg-grid-item :col="12">
        <bg-card>
          <div class="mb-16">
            <template v-if="loading">
              <bg-skeleton width="150px" height="25px" />
            </template>

            <div class="section-title-with-button" v-else>
              <div class="flex align-center">
                <bg-text size="heading-5"> Detail Kerja Sama </bg-text>
                <bg-label-rainbow :color="contractStatus.color" class="ml-8">
                  {{ contractStatus.text | capitalize }}
                </bg-label-rainbow>
                <bg-label-rainbow
                  v-if="labels.day_ended_contract"
                  color="yellow"
                  class="ml-8"
                >
                  {{ labels.day_ended_contract }}
                </bg-label-rainbow>
                <bg-label-rainbow
                  v-if="labels.renewal_status"
                  color="blue"
                  class="ml-8"
                >
                  {{ labels.renewal_status }}
                </bg-label-rainbow>
              </div>
              <div>
                <router-link v-if="accessEditKontrak" :to="editRoute">
                  <bg-button class="mr-16" :disabled="!isButtonEditEnabled"
                    >Ubah</bg-button
                  >
                </router-link>

                <router-link
                  v-if="accessAkhiriKontak"
                  :to="terminateContractRoute"
                >
                  <bg-button class="mr-16" :disabled="!canTerminated">
                    Akhiri Kontrak
                  </bg-button>
                </router-link>

                <router-link v-if="accessPerpanjangKontrak" :to="renewRoute">
                  <bg-button variant="primary" :disabled="isRenewDisabled"
                    >Perpanjang Kontrak</bg-button
                  >
                </router-link>
              </div>
            </div>
          </div>

          <bg-divider />

          <bg-grid v-if="loading" class="bg-u-mt-xl">
            <bg-grid-item>
              <bg-skeleton width="40%" height="100px" />
            </bg-grid-item>
          </bg-grid>

          <div v-else class="flex bg-u-py-xl">
            <div style="width: 320px">
              <bg-text size="body-1"> Perpanjang Kontrak Otomatis </bg-text>

              <bg-text size="body-2" class="bg-u-mt-xxs">
                Jika diaktifkan, sistem akan secara otomatis memperpanjang
                kontrak kerja sama pada properti ini saat kontrak berakhir.
              </bg-text>
            </div>

            <bg-switch
              v-model="toggleAutoRenewal"
              class="bg-u-ml-md"
              :disabled="!isAutoRenewalToggleable || isAutoRenewalLoading"
              @input="handleToggleAutoRenewal"
            />
          </div>

          <bg-divider />

          <bg-grid v-if="loading" class="mt-24">
            <bg-grid-item v-for="i in 4" :cols="3" :key="i">
              <bg-skeleton width="60%" height="20px" />
            </bg-grid-item>
          </bg-grid>

          <template v-else>
            <bg-grid class="mt-24">
              <bg-grid-item :col="3">
                <bg-list-item title="Jenis Produk">
                  {{ detail.product_type }}
                </bg-list-item>
              </bg-grid-item>

              <bg-grid-item :col="3">
                <bg-list-item title="Model Kerja Sama">
                  {{ detail.revenue_model | capitalize }}
                </bg-list-item>
              </bg-grid-item>

              <bg-grid-item :col="3">
                <bg-list-item title="Basic Commission">
                  {{ detail.basic_commision }}
                </bg-list-item>
              </bg-grid-item>

              <bg-grid-item :col="3">
                <bg-list-item title="Total Kamar">
                  {{ detail.room_total }}
                </bg-list-item>
              </bg-grid-item>
            </bg-grid>

            <bg-divider />

            <bg-grid class="mt-24">
              <bg-grid-item :col="3">
                <bg-list-item title="Tipe Add On JP">
                  {{ detail.type_add_on_jp | nullable }}
                </bg-list-item>
              </bg-grid-item>

              <bg-grid-item :col="3">
                <bg-list-item title="Persentase Add On JP">
                  {{ detail.percentage_add_on_jp | nullable }}
                </bg-list-item>
              </bg-grid-item>

              <bg-grid-item :col="3">
                <bg-list-item title="Jumlah Add On JP">
                  {{ detail.amount_add_on_jp | rupiah }}
                </bg-list-item>
              </bg-grid-item>
            </bg-grid>

            <bg-grid>
              <bg-grid-item :col="3">
                <bg-list-item title="Tipe Add On ADP">
                  {{ detail.type_add_on_adp | nullable }}
                </bg-list-item>
              </bg-grid-item>

              <bg-grid-item :col="3">
                <bg-list-item title="Persentase Add On ADP">
                  {{ detail.percentage_add_on_adp | nullable }}
                </bg-list-item>
              </bg-grid-item>

              <bg-grid-item :col="3">
                <bg-list-item title="Jumlah Add On ADP">
                  {{ detail.amount_add_on_adp | rupiah }}
                </bg-list-item>
              </bg-grid-item>
            </bg-grid>

            <bg-grid>
              <bg-grid-item :col="12" class="mb-8">
                <bg-text size="title-4">
                  Ketentuan Komisi dari Penyewa Booking
                </bg-text>
              </bg-grid-item>
              <bg-grid-item :col="3">
                <bg-list-item title="Pendapatan Pemilik">
                  {{ detail.percentage_pendapatan_owner | nullable }}
                </bg-list-item>
              </bg-grid-item>

              <bg-grid-item :col="3">
                <bg-list-item title="Pendapatan Mamikos">
                  {{ detail.percentage_pendapatan_mamikos | nullable }}
                </bg-list-item>
              </bg-grid-item>
            </bg-grid>

            <bg-grid
              v-if="
                detail.existing_tenant_comission_owner ||
                detail.existing_tenant_comission_mamikos
              "
            >
              <bg-grid-item :col="12" class="mb-8">
                <bg-text size="title-4">
                  Ketentuan Komisi dari Penyewa DBET
                </bg-text>
              </bg-grid-item>
              <bg-grid-item :col="3">
                <bg-list-item title="Pendapatan Pemilik">
                  {{ detail.existing_tenant_comission_owner | nullable }}
                </bg-list-item>
              </bg-grid-item>
              <bg-grid-item :col="3">
                <bg-list-item title="Pendapatan Mamikos">
                  {{ detail.existing_tenant_comission_mamikos | nullable }}
                </bg-list-item>
              </bg-grid-item>
            </bg-grid>

            <bg-divider />

            <bg-grid class="mt-24">
              <bg-grid-item :col="3" class="mb-0">
                <bg-list-item title="Jangka Waktu Kerja Sama">
                  {{ detail.jangka_waktu_kerja_sama }} Bulan
                </bg-list-item>
              </bg-grid-item>

              <bg-grid-item :col="3" class="mb-0">
                <bg-list-item title="Awal Kerja Sama">
                  {{ detail.start_contract }}
                </bg-list-item>
              </bg-grid-item>

              <bg-grid-item :col="3" class="mb-0">
                <bg-list-item title="Akhir Kerja Sama">
                  {{ detail.end_contract }}
                </bg-list-item>
              </bg-grid-item>

              <bg-grid-item :col="3">
                <bg-list-item title="Biaya Keanggotaan">
                  <template v-if="detail.biaya_keanggotaan">{{
                    detail.biaya_keanggotaan | rupiah
                  }}</template>
                  <template v-else>Tidak ada</template>
                </bg-list-item>
              </bg-grid-item>
            </bg-grid>
          </template>
        </bg-card>
      </bg-grid-item>
    </bg-grid>
  </section>
</template>

<script>
import {
  BgGrid,
  BgGridItem,
  BgCard,
  BgText,
  BgDivider,
  BgListItem,
  BgSkeleton,
  BgButton,
  BgLabelRainbow,
  BgSwitch,
} from 'bangul-vue';
import { capitalize } from 'Utils/typography';
import { rupiahFormatter } from 'Utils/formatter';

import dayjs from 'dayjs';

export default {
  name: 'PartnershipDetail',

  components: {
    BgText,
    BgGrid,
    BgGridItem,
    BgCard,
    BgDivider,
    BgListItem,
    BgSkeleton,
    BgButton,
    BgLabelRainbow,
    BgSwitch,
  },

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    detail: {
      type: Object,
      default: () => ({}),
    },
    labels: {
      type: Object,
      default: () => ({}),
    },
    propertyId: {
      type: [String, Number],
      default: '',
    },
  },

  data() {
    return {
      toggleAutoRenewal: false,
      isAutoRenewalLoading: false,
    };
  },

  filters: {
    capitalize: value => (value ? capitalize(value) : value),
    nullable: value => value || '-',
    rupiah: value => {
      if (value === '-' || !Number.isFinite(value)) {
        return value;
      }

      return rupiahFormatter(value);
    },
  },

  computed: {
    accessEditKontrak() {
      return this.$store.getters.xCheckUserPermission('edit-kontrak');
    },
    accessPerpanjangKontrak() {
      return this.$store.getters.xCheckUserPermission('perpanjang-kontrak');
    },
    accessAkhiriKontak() {
      return this.$store.getters.xCheckUserPermission('akhiri-kontrak');
    },

    isRenewDisabled() {
      const seventyDaysBeforeEndContractDate = dayjs(
        this.detail.end_contract
      ).subtract(70, 'day');
      const today = dayjs();

      return (
        today.isBefore(seventyDaysBeforeEndContractDate) ||
        this.detail.status === ('auto_terminated' || 'draft_renewal') ||
        this.labels.renewal_status
      );
    },

    terminateContractRoute() {
      return {
        name: 'terminate-contract',
        params: {
          propertyId: this.propertyId,
          contractId: this.detail.id,
        },
      };
    },
    editRoute() {
      return {
        name: 'edit.partnership-detail',
        params: {
          propertyId: this.propertyId,
        },
      };
    },
    renewRoute() {
      return {
        name: 'edit.renew-contract',
        params: {
          propertyId: this.propertyId,
        },
      };
    },
    canTerminated() {
      return (
        this.detail.status === 'active' || this.detail.status === 'expired'
      );
    },
    contractStatus() {
      const colors = {
        probation: 'yellow',
        live: 'green',
        expired: 'grey',
      };

      return {
        color: colors[this.labels.contract_status] || 'grey',
        text: this.labels.contract_status,
      };
    },
    isButtonEditEnabled() {
      return ['active'].includes(this.detail.status);
    },
    isAutoRenewalToggleable() {
      const today = dayjs();
      const endContract = dayjs(this.detail.end_contract);
      const gapDays = endContract.diff(today, 'day')

      return gapDays > 30;
    },
  },

  watch: {
    detail: {
      deep: true,
      immediate: true,
      handler(detail) {
        this.toggleAutoRenewal = detail.is_contract_auto_renewal;
      },
    },
  },

  methods: {
    handleToggleAutoRenewal() {
      this.isAutoRenewalLoading = true;

      this.$emit(
        'auto-renewal',
        { action: this.toggleAutoRenewal ? 'activate' : 'deactivate' },
        _ => {
          this.isAutoRenewalLoading = false;
        }
      );
    },
  },
};
</script>
